<template>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" id="branch_create" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card card-custom">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title a">
                        <h3 class="card-label">
                            Tạo chi nhánh
                        </h3>
                    </div>
                    <div class="card-toolbar">
                        <router-link
                                class="font-weight-bold font-size-3  btn btn-secondary"
                                :to="{ name: 'branch-index' }"
                        ><i class="fas fa-angle-double-left"></i> Quay lại
                        </router-link>
                    </div>
                </div>
                <!--end::Header-->
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tên chi nhánh
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="name" name="Tên chi nhánh" rules="required"
                                                    v-slot="{ errors,classes }">
                                    <input type="text" ref="name" name="name" class="form-control" v-model="branch.name"
                                           placeholder="Tên chi nhánh" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Chọn trung tâm
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Trung tâm" rules="required" v-slot="{ errors,classes }">
                                    <multiselect v-model="cacheCenter"
                                                 :options="centers"
                                                 placeholder="Chọn trung tâm"
                                                 label="name"
                                                 track-by="name"
                                                 @input="selectCenter($event)"
                                                 :class="classes"
                                    ></multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <input type="hidden" name="center_id" :value="branch.center_id">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Số điện thoại
                                    <span class="text-danger"></span></label>
                                <ValidationProvider vid="phone" name="Số điện thoại"
                                                    :rules="{ regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                                    v-slot="{ errors,classes }">
                                    <input type="number" name="phone" class="form-control"
                                           v-model="branch.phone"
                                           placeholder="Số điện thoại" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="phone" data-validator="notEmpty" class="fv-help-block">
                                          {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Địa chỉ
                                    <span class="text-danger"></span></label>
                                <textarea class="form-control" rows="3" v-model="branch.address"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tỉnh/thành phố
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Tỉnh thành phố" rules="required" v-slot="{ errors,classes }">
                                    <multiselect :options="provinces"
                                                 placeholder="Tỉnh/thành phố"
                                                 label="name"
                                                 track-by="name"
                                                 v-model="cacheProvince"
                                                 @input="selectProvince($event)"
                                                 :class="classes"
                                    ></multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                          {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <input type="hidden" name="province" :value="branch.province">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Quận/huyện
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Quận huyện" rules="required" v-slot="{ errors,classes }">
                                    <multiselect :options="districts"
                                                 placeholder="Quận/huyện"
                                                 label="name"
                                                 track-by="name"
                                                 v-model="cacheDistrict"
                                                 @input="selectDistrict($event)"
                                                 :class="classes"
                                    ></multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                          {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
<!--                    <div class="row">-->
<!--                        <div class="col-md-6">-->
<!--                            <div class="form-group">-->
<!--                                <label>Hệ số kinh doanh-->
<!--                                    <span class="text-danger"></span></label>-->
<!--                                <input type="number" step="any" min="0" ref="he_so_kinh_doanh" name="he_so_kinh_doanh"-->
<!--                                       class="form-control" placeholder="Hệ số kinh doanh"-->
<!--                                       v-model="branch.he_so_kinh_doanh">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-md-6">-->
<!--                            <div class="form-group">-->
<!--                                <label>Hệ số lên cấp-->
<!--                                    <span class="text-danger"></span></label>-->
<!--                                <input type="number" step="any" min="0" ref="he_so_len_cap" name="he_so_len_cap"-->
<!--                                       class="form-control" placeholder="Hệ số lên cấp"-->
<!--                                       v-model="branch.he_so_len_cap">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Kinh độ
                                    <span class="text-danger"></span></label>
                                <input type="text" ref="name" name="name" class="form-control" placeholder="Kinh độ"
                                       v-model="branch.latitude">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Vĩ độ
                                    <span class="text-danger"></span></label>
                                <input type="text" ref="name" name="name" class="form-control" placeholder="Vĩ độ"
                                       v-model="branch.longitude">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Google Maps
                                    <span class="text-danger"></span></label>
                                <!--<vue-google-autocomplete-->
                                <!--id="map"-->
                                <!--classname="form-control"-->
                                <!--placeholder="Start typing"-->
                                <!--v-on:placechanged="getAddressData"-->
                                <!--&gt;-->
                                <!--</vue-google-autocomplete>-->
                                <input type="text" ref="name" name="name" class="form-control" placeholder="Google Maps"
                                       v-model="branch.google_maps">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                              <label>Loại chi nhánh
                                <span class="text-danger">*</span></label>
                              <ValidationProvider vid="name" name="Loại chi nhánh" rules="required"
                                                  v-slot="{ errors,classes }">
                                <el-select v-model="branch.type"
                                           class="w-100">
                                  <el-option v-for="(item, index) in list_type_branch"
                                             :key="index"
                                             :value="item.id"
                                             :label="item.name"
                                  >
                                    {{item.name}}
                                  </el-option>
                                </el-select>
                                <div class="fv-plugins-message-container">
                                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                      errors[0]
                                    }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>
                                    <span class="text-danger"></span></label>
                                    <div class="checkbox-inline">
                                        <label class="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" v-model="branch.status">
                                            <span></span>Hiển thị</label>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Thông tin cấp trên-->
                <div class="separator separator-dashed my-5"></div>
                <div class="card-header">
                    <div class="card-title align-items-start flex-column">
                        <button
                                type="submit"
                                class="btn btn-success mr-2"
                        >
                            Tạo mới
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>

</template>
<script>
    import {mapGetters,} from "vuex";
    import Multiselect from 'vue-multiselect'
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {GET_PROVINCES} from "../../../core/services/store/public/provinces.module";
    import {GET_DISTRICTS} from "../../../core/services/store/public/districts.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import {CREATE_BRANCH} from "../../../core/services/store/center/branch.module";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {LIST_TYPE} from "@/assets/js/components/branchs";

    import VueGoogleAutocomplete from 'vue-google-autocomplete'

    export default {
        components: {
            Multiselect,
            DatePicker,
            VueGoogleAutocomplete
        },
        name: "BranchCreate",
        data() {
            return {
                editorSettings: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                branch: {
                    center_id: '',
                    name: '',
                    phone: '',
                    address: '',
                    province_id: '',
                    district_id: '',
                    latitude: '',
                    longitude: '',
                    google_maps: '',
                    he_so_kinh_doanh: '',
                    he_so_len_cap: '',
                    status: true,
                    name_alias: '',
                    type: ''
                },
                centers: [],
                provinces: [],
                districts: [],

                // cache
                cacheProvince: [],
                cacheDistrict: [],
                cacheCenter: [],

                list_type_branch: LIST_TYPE
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý chi nhánh", route: 'branch-index'},
                {title: "Tạo chi nhánh"}
            ]);
            this.getCenters()
            this.getProvinces()
            this.getDistricts()
        },
        computed: {
            ...mapGetters(["errorsAccountType"]),
        },
        methods: {
            getAddressData: function (addressData, placeResultData, id) {
                this.address = addressData;
            },
            getProvinces() {
                this.$store.dispatch(GET_PROVINCES, []).then((res) => {
                    if (!res.error) {
                        this.provinces = res.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getDistricts() {
                this.$store.dispatch(GET_DISTRICTS, {
                    province_id: this.branch.province_id
                }).then((res) => {
                    if (!res.error) {
                        this.districts = res.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            onSubmit() {
                this.$store.dispatch(CREATE_BRANCH, this.branch).then((res) => {
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Tạo mới',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$router.push({name: "branch-index"}).then(() => {
                            this.$bvToast.toast(res.message, {
                                title: 'Tạo mới',
                                variant: 'success',
                                solid: true
                            });
                        })
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
            },
            selectProvince($event) {
                this.removeSelectDistrict()
                let id = $event.id
                this.branch.province_id = id
                this.getDistricts()
            },
            selectDistrict($event) {
                let id = $event.id
                this.branch.district_id = id
            },
            selectCenter($event) {
                let id = $event.id != null && $event.id != undefined ? $event.id : ''
                if (id != '') {
                    this.branch.center_id = id
                }
            },
            removeSelectDistrict() {
                this.branch.district_id = ''
                this.cacheDistrict = {}
            }
        }
    }
</script>
<style scoped>
    .mx-datepicker {
        width: 100% !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
